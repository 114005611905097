<template>
  <div class="questons-uploader">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <p class="title is-5">Rules:</p>
        <ol class="conditions">
          <li>Document must be a single plain file.</li>
          <li>
            The expected approach is to have a consistent number of options per
            question. In this case set the number of options per question in the
            file before starting.
          </li>
          <li>
            If you are having varying number of options per question, then you
            MUST bolden the questions of the file.
          </li>
        </ol>
        <div>
          <b-field label="Question width">
            <b-input v-model="questionWidth"></b-input>
          </b-field>
        </div>
        <input class="input" type="file" id="input" />
        <p class="subtitle is-6 has-text-right">
          {{ questions.length }} questions having
          {{ questionWidth - 1 || 0 }} options each.
        </p>
        <div class="columns">
          <div class="column">
            <div class="list">
              <template v-if="questions">
                <div
                  v-for="(item, index) in questions"
                  :key="index + 1"
                  class="e-courses-list"
                >
                  <div class="question-container">
                    <span class="question-index">{{ index + 1 }}.</span>
                    <p class="question" v-html="item.question" />
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="button btn-120 is-primary is-capitalized is-pulled-right"
          @click="upload"
        >
          Upload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import mammoth from 'mammoth'
import Question from '@/assets/js/question'
import { crudNotification } from '@/assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'Questions - Docx Uploader',
      htmlFile: null,
      questionWidth: 5,
      questions: [],
    }
  },
  methods: {
    format(value) {
      // Use to format fractions
      // A//b/c
      if (/\d+\/{2}\d+\/\d+/.test(value)) {
        let data = []
        value
          .trim()
          .split('//')
          .filter((item) => {
            if (/\//.test(item)) {
              let [n, d] = item.split('/')
              data.push(`<sup>${n}</sup>&frasl;<sub>${d}</sub>`)
            } else {
              data.push(item)
            }
          })
        value = data.join(' ')
      }
      return value
    },
    upload() {
      // Add
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UploadECourseWorkQuestions($id: Int!, $questions: JSON!) {
              uploadECourseWorkQuestions(
                input: { id: $id, questions: $questions }
              ) {
                eCourseWork {
                  id
                }
                errors
              }
            }
          `,
          // Parameters
          variables: {
            id: parseInt(this.$route.params.id),
            questions: this.questions,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.uploadECourseWorkQuestions.errors,
            'Successfully uploaded.'
          ).then(() => {
            this.$router.push(
              `/school/${this.$route.params.school_id}/e_course_work/${this.$route.params.id}/questions`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    prepare() {
      // This method is used to prepare docx file containing multiple questions
      // having the following format
      // <ol><li></li>...</ol>
      // See the Questions folder for a sample
      // The questions must follow the structure provided
      this.questions = []

      const rawData = []

      this.htmlFile
        .replace('<ol>', '')
        .replace('</ol>', '')
        .split('<li>')
        .forEach((item) => {
          if (item !== '') {
            rawData.push(item.replace('</li>', '').replace('/t', '').trim())
          }
        })

      for (let index = 0; index < rawData.length; ) {
        let questionOptions = []
        // splice changes the content of the rawData array
        // by removing 5 items every iteration
        let [question, ...options] = rawData.splice(index, this.questionWidth)

        for (let i = 0; i < options.length; i++) {
          questionOptions.push(`<li>${this.format(options[i])}</li>`)
        }

        this.questions.push(
          new Question(
            `<div>${this.format(question)}</div><div><ol>${questionOptions.join(
              '\n\t'
            )}</ol></div>`
          ).create()
        )
      }
    },
    prepare_questions_with_heading() {
      const questionCollection = []
      // Remove title info
      // Such as subject and school class name
      const rawData = this.htmlFile.split(/<h2>/).splice(1)
      rawData.forEach((data) => {
        let questionDetails = {}

        const [instruction, articleAndQuestions] = data.split(/<\/h2>/)

        const [article, questions] = articleAndQuestions.split(
          /<\/h3><ol>|<\/p><table>|<\/h2><ol>/
        )

        questionDetails['instruction'] = instruction
        questionDetails['article'] = article
        questionDetails['questions'] = questions

        questionCollection.push(questionDetails)
      })
      console.log(questionCollection)
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Questions',
        route: `/school/${this.$route.params.school_id}/e_course_work/${this.$route.params.id}/questions`,
      },
    ])

    document.getElementById('input').addEventListener(
      'change',
      (e) => {
        const file = e.target.files[0]

        const reader = new FileReader()

        reader.addEventListener('load', (e) => {
          const options = {
            styleMap: ["p[style-name='Heading 1'] => h1"],
          }

          docx2html(file).then((html) => {
            console.log(html)
          })

          mammoth
            .convertToHtml({ arrayBuffer: e.target.result }, options)
            .then((result) => {
              console.log(result)
              this.htmlFile = result.value
              const messages = result.messages

              // If the file contains Heading tags use prepare_questions_with_heading
              if (/<h2>/.test(this.htmlFile)) {
                this.prepare_questions_with_heading()
              } else {
                this.prepare()
              }
            })
            .done()
        })

        reader.readAsArrayBuffer(file)
      },
      false
    )
  },
}
</script>

<style lang="scss" scoped>
.conditions {
  font-size: 0.9em;
  margin-bottom: 16px;
}

#input {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  background-color: #dddbdb;
}

.e-courses-list {
  font-size: 0.9em;
  border-bottom: orange 1px solid;
  padding: 10px 10px;
}

.question-container {
  display: flex;
  margin-bottom: 5px;
}

.question-index {
  flex: 1;
}

.question {
  flex: 90%;
}

.options-container {
  padding-left: 16px;
}

.list {
  height: 1200px;
  overflow-y: scroll;
  padding: 8px;
}
</style>
